import { Injectable } from '@angular/core';
declare var gtag: any;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  setUserId(mobileNumber: string | null){
    // ga('set', {'user_id': mobileNumber}); // Set the user ID using signed-in user_id.
    if(!mobileNumber)return;
    gtag('user_id', mobileNumber);
  }

  sendPageView(){
    if (document.location.pathname.indexOf('expired-link/') > -1) {
      gtag('event', 'page_view', {
        page_title: 'expiry-page',
        page_location: '/expiry-page'
      });
      return;
    }

    if (document.location.pathname.indexOf('') > -1) {
      gtag('event', 'page_view', {
        page_title: 'expiry-page',
        page_location: '/expiry-page'
      });
      return;
    }

    if(location.pathname.indexOf('track/') > -1){
      gtag('event', 'page_view', {
        page_title: 'track',
        page_location: '/track'
      });
      return;
    }

    if(location.pathname.indexOf('rating/') > -1){
      const page = 'feed-back'
      gtag('event', 'page_view', {
        page_title: 'rating',
        page_location: '/rating'
      });
      return;
    }

    if(location.pathname.indexOf('order-rating') > -1){
      gtag('event', 'page_view', {
        page_title: 'order-star-rating',
        page_location: '/order-star-rating'
      });
      return;
    }

    if(location.pathname.indexOf('order-rating-feedback') > -1){
      gtag('event', 'page_view', {
        page_title: 'order-rating-feedback',
        page_location: '/order-rating-feedback'
      });
      return;
    }
    if (location.pathname.indexOf('customer-verification') > -1) {
      gtag('event', 'page_view', {
        page_title: 'customer-verification',
        page_location: '/customer-verification'
      });
      return;
    }
  }

  sendEvent(eventName: string, eventParameters:any ){
    gtag('event', eventName, eventParameters);
  }

  sendPageLoadTime(){
    // Feature detects Navigation Timing API support.
    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      var timeSincePageLoad = Math.round(performance.now());

      // Sends the timing hit to Google Analytics.
      gtag('event', 'timing_complete', {
        'name': 'load',
        'value': timeSincePageLoad,
        'event_category': 'JS Dependencies'
      });
    }
  }


}
