import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {TrackingService} from "./tracking/services/tracking/tracking.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {AnalyticsService} from "./shared/analytics.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Shadowfax-order-traking';
  subscription: Subscription;
  feedbackflag:boolean;

  orderId:string ='';
  constructor(private trackingService:TrackingService, private router:Router, private analyticService: AnalyticsService, private cdr: ChangeDetectorRef){

    this.subscription = this.trackingService.getOrderId().subscribe(orderId => {
      if (orderId.id != '') {
        this.feedbackflag=false;
        this.orderId = orderId.id;
        // this.analyticService.sendEvent("HomeScreen","Rate us",this.orderId)
        this.analyticService.sendEvent('rate_us',{
          pageName: 'HomeScreen',
          orderId: this.orderId
        })
      } else {
        // clear messages when empty message received
        this.feedbackflag=true;
        this.orderId = orderId.id || '';
        // this.analyticService.sendEvent("TechTracking","Opened without Parameter",this.orderId)
        this.analyticService.sendEvent('opened_without_parameter', {
          pageName: 'TechTracking',
          orderId: this.orderId
        })

      }
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.orderId = this.trackingService.getOrderId1() || '';
  }


  onFeedBackClick(){
    if(this.trackingService.getOrderId1() != undefined) {
      this.router.navigate(["/rating/"+this.trackingService.getOrderId1()]);
    } else {
      this.router.navigate(["/"]);
    }
  }

}
