import { Injectable } from '@angular/core';
import {NgxUiLoaderService} from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private ngxService: NgxUiLoaderService) { }

  showLoader(){
    this.ngxService.start();
  }

  hideLoader(){
    this.ngxService.stop();
  }
}
