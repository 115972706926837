import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private subject = new Subject<any>();
  private baseURL: string = environment.apiEndPoint;
  private starRating:number;
  private orderId:string;
  private reason:number;

  constructor(private http: HttpClient) { }

  getRiderLiveDetails(hashOrder:any) {
    return this.http.get<any>(this.baseURL + 'tracking/riderLocation/' + hashOrder+'/');
  }

  verifyUser(orderId:any,data:any) {
    return this.http.post<any>(this.baseURL + `tracking/order_hash/${orderId}/`, data);
  }

  getOrderDetails(hashOrder:any) {
    return this.http.get<any>(this.baseURL + 'tracking/orderDetail/' + hashOrder+'/');
  }

  getOrderStatus(hashOrder:any) {
    return this.http.get<any>(this.baseURL + 'tracking/orderStatus/' + hashOrder+'/');
  }

  getMapDetails(hashOrder:any) {
    // http://hobbit3.us-east.demo.shadowfax.in/tracking/map_details/EAC6AD57214F96735FCEBF82DB9F8D76/
    return this.http.get<any>(this.baseURL + 'tracking/map_details/' + hashOrder+'/');
    // return this.http.get<any>("http://hobbit3.us-east.demo.shadowfax.in/" + 'tracking/map_details/' + hashOrder+'/');
  }

  giveFeedback(hashOrder:any,payload){
    return this.http.put<any>(this.baseURL + 'tracking/feedback/' + hashOrder+'/',payload);

  }
  setRating(starRating: number, reason: number) {
    this.starRating = starRating;
    this.reason = reason;
  }

  setOrderId(orderId) {
    this.orderId = orderId;
    this.subject.next({ id: orderId });
  }

  getOrderId1() {
   return this.orderId;
  }

  getStarRating() {
    return this.starRating;
  }

  getReasonRating() {
    return this.reason;
  }
  getOrderId(): Observable<any> {
    return this.subject.asObservable();
  }
}
