import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadComponent: () => import('./expired-link/expired-link.component').then(a => a.ExpiredLinkComponent)
  },
  {
    path: 'track/:id',
    loadChildren: () => import('./tracking/tracking-view.module').then(m => m.TrackingViewModule)
  },
  {
    path: 'rating/:id',
    loadComponent: () => import('./rating/rating/rating.component').then(a => a.RatingComponent)
  },
  {
    path: 'expired-link',
    loadComponent: () => import('./expired-link/expired-link.component').then(a => a.ExpiredLinkComponent)
  },
  {
    path: 'order-rating/:id',
    loadComponent: () => import('./rating/order-delivery-rating/order-delivery-rating.component').then(a => a.OrderDeliveryRatingComponent)
  },
  {
    path: 'order-mdnd/:id',
    loadComponent: () => import('./rating/order-mdnd/order-mdnd.component').then(m => m.OrderMdndComponent)
  },
  {
    path: 'order-rating-feedback/:id',
    loadComponent: () => import('./rating/rating-feedback/rating-feedback.component').then(m => m.RatingFeedbackComponent)
  },
  {
    path: 'customer-verification',
    loadComponent: () => import('./customer-verification/customer-verification.component').then(a => a.CustomerVerificationComponent)
  },

  {
    path: '**',
    redirectTo:'',
    pathMatch:'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' ,paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
