<!--The content below is only a placeholder and can be replaced.-->
<header>
  <div fxLayout="row" fxLayoutAlign="space-between" class="header-container" >
    <div fxFlex class="logo" >
      <img src="assets/images/logo.png">
    </div>
    <button class="feedback-btn" mat-button (click)="onFeedBackClick()" [disabled]="orderId == ''">
      <img src="assets/images/feedback-icon.png" class="feedback-icon">
      <!--<span class="material-icons">message</span>-->
      <span class="feedback-text">
        Feedback
      </span>
    </button>
  </div>
</header>
<ngx-ui-loader></ngx-ui-loader>
<router-outlet></router-outlet>

