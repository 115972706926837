import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
let sentryDns: string = "https://b992c77453b4414f8c8568f86f905cfb@sentryfrontend.shadowfax.in/3";
let replaysSessionSampleRate: number = 0
let replaysOnErrorSampleRate: number = 0
if (environment.production) {
  sentryDns = "https://8dd188b5e9a14943b53dcb05972e646f@sentryfrontend.shadowfax.in/17";
  replaysSessionSampleRate = 0;
  replaysOnErrorSampleRate = 0;
  enableProdMode();
}
Sentry.init({
  dsn: sentryDns,
  replaysSessionSampleRate: replaysSessionSampleRate,
  replaysOnErrorSampleRate: replaysOnErrorSampleRate,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
