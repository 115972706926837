import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {CommonService} from "../shared/common.service";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor( private commonService : CommonService,private router:Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 403) {
              // redirect to expired if 403 response returned from api
              this.router.navigate(["/"]);
             // console.log("in intersceptor")
            }
            this.commonService.hideLoader();
            const error = err.error || err.statusText;
            return throwError(error);
        }))
    }
}
